/*calendar
.fc-content {
  position: relative;
}
.fc-title {
  position: absolute;
  white-space: nowrap;
  transform: translateX(0);
  transition: 1s;
}
.fc-title:hover {
  transform: translateX(calc(100px - 150%));
}*/
#new-trackerButtonsArea .careButton {
  margin: 10px;
}
#trackerButtonsArea .careButton {
  margin: 10px;
}
.swal2-overlay {
  z-index: 1051;
}
#lps_callToActionBoxes {
  display: flex;
  align-items: center;
  margin: 0px auto 15px auto;
}
#lps_callToActionBoxesEnrolled {
  display: flex;
  align-items: center;
  margin: 15px auto 0px auto;
}
#lps_callToActionBoxes .callToActionBox,
#lps_callToActionBoxesEnrolled .callToActionBox {
  margin: 5px 5px;
  border-color: #dddada;
}
.dismissAllNotifications {
  font-size: 13px;
  font-weight: bold;
  color: #ff4141;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dismissAllNotifications i {
  font-size: 16px;
  margin-left: 10px;
}
.dismissAllNotifications span {
  line-height: 16px;
}
@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}
.cr_sectionHeader {
  font: 12px Raleway;
  font-weight: bold;
  width: 120px;
  height: 23px;
  line-height: 23px;
  text-align: left;
  background-color: #95c103;
  color: white;
  padding-left: 5px;
  display: inline-block;
}
.cr_box {
  background: white;
  box-shadow: 0 2px 3px #d9d9d9;
  border: 1px solid #cecece;
  margin: 10px;
}
.regLive_sectionTitle {
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: -15px;
}
.regLive_membershipLevel {
  width: 150px;
  display: inline-block;
  border: 1px solid #cacaca;
  border-radius: 3px;
  padding: 5px;
  font-weight: bold;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
}
.regLive_membershipLevel:hover {
  background-color: #489dff;
  color: #ffffff;
}
.regLive_membershipSection {
  display: none;
}
.regLive_callHistory {
  margin-bottom: 10px;
}
.tableRowButton {
  border-radius: 50%;
  border: 1px solid #cacaca;
  width: 20px;
  height: 20px;
  text-align: center;
  padding-top: 2px;
  font-size: 10px;
  cursor: pointer;
  margin: 0px 2px;
  background-color: #ffffff;
}
.tableRowButton:hover {
  color: #000000;
  border-color: #000000;
}
@media print {
  #cmdDisplay {
    background-color: white !important;
    height: auto !important;
    width: 100% !important;
    position: absolute !important;
    top: 0;
    padding-top: 50px;
    left: 0;
    margin: 0 !important;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    z-index: 2000 !important;
  }
  .fc-button {
    display: none;
  }
  .noShowOnPrint {
    display: none !important;
  }
  .containerMain {
    overflow: unset !important;
    overflow-x: unset !important;
    min-height: auto !important;
    border: none !important;
  }
  #notifications,
  #buttonNotifications {
    display: none;
  }
}
#appointmentCalendar td,
#appointmentCalendar tr {
  cursor: pointer;
}
/*
 Calendar */

.myCCMSectionTitle {
  background-color: #95c103;
  color: whitesmoke;
  padding: 5px;
  width: 170px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  text-align: center;
  font: 14px Raleway;
}
.myCCMSectionTitleBlue {
  background-color: #1a6494;
}
.myCCMSectionContent {
  border-top: 2px solid #95c103;
  padding-top: 15px;
  font-family: Comfortaa;
}
.myCCMSectionContentBlue {
  border-top: 2px solid #1a6494;
}
.monthSwitcherCCM {
  display: inline-block;
  width: 36px;
}
.monthSwitcherCCM:hover {
  color: #489dff;
  cursor: pointer;
}
.monthSwitcherCCMSelected {
  color: #489dff;
  cursor: pointer;
  font-weight: bold;
}
.monthSwitcherCCMSelected > span {
  color: #489dff;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}
.signedSwitcherCCM {
  color: #cacaca;
  cursor: pointer;
}
.signedSwitcherCCM:hover {
  color: #489dff;
  cursor: pointer;
}
.signedSwitcherCCMSelected {
  color: #489dff;
  cursor: pointer;
  font-weight: bold;
}
.signedSwitcherCCMSelected > span {
  color: #489dff;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}
.ccmDetailsTile {
  max-width: 100px;
  max-height: 80px;
  font-size: 15px;
  display: inline-block;
  margin: 15 10 0 10;
  border: 2px solid #103c59;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}
.ccmDetailsTile:hover {
  color: #489dff;
  border-color: #489dff;
}
#banner {
  background-color: RGB(26, 100, 148);
  height: 53px;
  width: 100%;
  position: relative;
  z-index: 960;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.textStrike {
  text-decoration: line-through;
}
.tiltedDivTop {
  -webkit-transform: rotate(24deg);
  -moz-transform: rotate(24deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2); /*for IE*/
  margin: 7 0 0 -5;
  width: 120%;
  background-color: #fdede8;
  height: 15px;
}
.chartBox {
  border: 1px solid black;
  width: 100%;
  position: relative;
  background-color: white;
}
.chartColumnColor {
  display: table-cell;
  width: 40px;
  max-width: 40px;
  max-width: 40px;
  vertical-align: top;
  overflow: hidden;
}
.chartBadBox {
  background: repeating-linear-gradient(
    45deg,
    #ffffff,
    #ffffff 10px,
    #ffffff 10px,
    #eaeaea 20px
  );
}
.chartColumn {
  display: table-cell;
  min-width: 70px;
  width: 70px;
  max-width: 70px;
  vertical-align: top;
  overflow: hidden;
}
.tiltedDivBottom {
  -webkit-transform: rotate(24deg);
  -moz-transform: rotate(24deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2); /*for IE*/
  margin: -10 0 0 -2;
  width: 150%;
  background-color: #fdede8;
  height: 20px;
}
.cmdTileTitle {
  width: 80%;
  font-size: 18px;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
  color: #1a6494;
}
.cmdTileDescription {
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
}
.cmdTileIcons {
  width: 80%;
  margin: 0 auto;
  margin-top: 15px;
  text-align: center;
}
.cmdTile {
  display: inline-block;
  height: 200px;
  width: 200px;
  background-color: #e8e8e8;
  border: 3px solid #1a6494;
  border-radius: 10px;
  cursor: pointer;
  vertical-align: top;
  margin: 20;
}
.cmdTile:hover {
  border: 3px solid #489dff;
}
.cmdCCMTile {
  display: none;
}
.modal {
  top: 50px;
}
.modal-dialog {
  margin-top: 100px;
}
.clickToCall {
  color: #489dff;
  cursor: pointer;
}
/* jQuery UI autocomplete*/
.ui-menu-item {
  /* font-family to all */
  font: 12px Raleway;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: none;
  background: #abd0fb;
  color: #000000;
}
.ui-autocomplete {
  max-height: 200px;
  max-width: 375px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden;
  border-radius: 5px;
  background: white;
}
.ui-autocomplete > li:nth-child(even) {
  background: #f3f3f3;
}
/* End jQuery UI autocomplete*/
#collaborationArea {
  margin: 0 auto;
  display: table;
  font-size: 12px;
  font-family: Raleway;
}
#collaborationArea > div > div {
  display: table-cell;
  border-right: 1px solid #c8c8c8;
  cursor: pointer;
  padding: 5px 0;
}
#collaborationArea > div > div > span {
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 16px;
  color: #c8c8c8;
}
#collaborationArea > div > div > div {
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  line-height: 14px;
}
/*Turn off number input spinners*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.graphicalTooltip {
  position: absolute;
  z-index: 1060;
}

/*========== ADDED THIS  for scrolling really long images==========*/
.lightbox {
  width: 100%; /* set arbitrary dimensions */
  height: 90%;
  overflow: scroll; /* causes .lightbox to be scrollable if children overflow it */
}
.lightbox img {
  max-width: none; /* max-width: 90%; <---- don't set this (otherwise image will never overflow parent)*/
  /*margin-top: 2%;*/
  overflow-y: visible; /* overflow-y: scroll; <---- don't set this (images can't contain anythying, so nothing can overflow from them*/
  height: auto; /* height: 100%; <---- don't set this (otherwise image will never overflow parent)*/
}
/*=======================*/
/*customize datepicker*/
#ui-datepicker-div {
  font-size: 10px;
}
.ui-datepicker-header {
  background: #f9f9f9;
  border: 0px;
  color: #000000;
}
.ui-widget-header .ui-icon {
  /* background-image: url(images/ui-icons_222222_256x240.png); */
}
/*end date picker*/
/*Percentage circle*/
.Perc_container {
  width: 30px;
  height: 30px;
  float: right;
}
.Perc_circle {
  position: relative;
  top: 5px;
  left: 5px;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #ffffff;
}
.Perc_active-border {
  position: relative;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #489dff;
  background-image: linear-gradient(91deg, transparent 50%, #cacaca 50%),
    linear-gradient(90deg, #cacaca 50%, transparent 50%);
}
/* End Percentage Circle*/
.PE_sectionSymbol {
  width: 100%;
  max-width: 150px;
  text-align: center;
  margin-bottom: 5px;
}
.PE_sectionName {
  width: 100px;
  text-align: left;
  font: 13px Raleway;
}
.PE_sectionLabels {
  text-align: right;
  width: 70px;
  display: table-cell;
  margin-left: 25px;
  font: 10px Raleway;
  padding-top: 45px;
}
.PE_sectionChart {
  width: 600px;
  display: table-cell;
  vertical-align: middle;
  margin-left: 5px;
  overflow-x: hidden;
}
.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.sectionButtonTooltip {
  border: 0px;
  background-color: black;
  color: white;
  border-radius: 5px;
  background-image: none;
}
.iti-flag {
  /* background-image: url("/vendor/intl-tel-input/build/img/flags.png"); */
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2 / 1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .iti-flag {
    /* background-image: url("/vendor/intl-tel-input/build/img/flags@2x.png"); */
  }
}

/*.intl-tel-input input,
.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type="text"],
.intl-tel-input.allow-dropdown input[type="tel"] {
  padding-right: 0px;
  padding-left: 0px;
  margin-left: 0;
}
.intl-tel-input input {
  padding-right: 0px;
}*/
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 10px;
}
div#fadeMe {
  opacity: 1;
  filter: alpha(opacity=20);
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  position: fixed;
}
div#messagesContainer {
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  overflow-y: scroll;
  height: 330px;
  width: 100%;
  margin-left: 10px;
}
@media all and (max-width: 920px) {
  #notifications {
    margin-left: 0px;
    /*left: 922px;*/
  }
}
.unselectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}
div#notifications {
  height: 100%;
  width: 0px;
  background: #e7f0fc; /*#F4F9FF;*/
  position: absolute;
  top: 60px;
  right: 0;
  border-radius: 25px 0 0 25px;
  padding-top: 15px;
  overflow-y: scroll;
  border: 1px solid #d5d5d5;
}
.newNotificationNotice {
  position: absolute;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  text-align: center;
  font-weight: bold;
  font-family: Helvetica Neue;
  line-height: 15px;
  font-size: 11px;
  top: 0;
  right: 0;
}
#bnCount {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 3px 9px;
  border: 1px solid white;
  border-radius: 100px;
  background: #ff6969; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* Standard syntax */
  -webkit-background-clip: padding-box;
  font: 16px/20px "Helvetica Neue", sans-serif;
  color: white;
  text-decoration: none;
  z-index: 1000;
}
.patientNewsBell {
  cursor: pointer;
  padding: 3px 6px;
  display: block;
  margin: 4 0 0 2;
  position: absolute;
  border-radius: 100px;
  background: #ff6969; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* Standard syntax */
  -webkit-background-clip: padding-box;
  font: bold 8px/10px "Helvetica Neue", sans-serif;
  color: white;
  text-decoration: none;
}
.patientNotifyBell {
  position: absolute;
  padding: 1px 4px;
  margin: 1px -1px;
  border-radius: 100px;
  background: #ff6969; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    top,
    #ff6969 0%,
    #ff0000 100%
  ); /* Standard syntax */
  -webkit-background-clip: padding-box;
  font: bold 8px/10px "Helvetica Neue", sans-serif;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
body > .ui-pnotify {
  top: 55px;
  z-index: 950;
}
.ui-pnotify .ui-pnotify-container,
.ui-pnotify-noborder {
  border-color: #cacaca;
  padding: 0;
}
.blink {
  -webkit-backface-visibility: hidden;
  -webkit-animation: noteBellBlink 1s linear infinite 0s;
  -moz-animation: noteBellBlink 1s linear infinite 0s;
  -o-animation: noteBellBlink 1s linear infinite 0s;
  -ms-animation: noteBellBlink 1s linear infinite 0s;
  animation: noteBellBlink 1s linear infinite 0s;
}
@-webkit-keyframes noteBellBlink {
  0% {
    opacity: 0.2;
    -webkit-animation-timing-function: ease-in;
  }
  50% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
  }
}
@-moz-keyframes noteBellBlink {
  0% {
    opacity: 0.2;
    -webkit-animation-timing-function: ease-in;
  }
  50% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
  }
}
@-o-keyframes noteBellBlink {
  0% {
    opacity: 0.2;
    -webkit-animation-timing-function: ease-in;
  }
  50% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
  }
}
@-ms-keyframes noteBellBlink {
  0% {
    opacity: 0.2;
    -webkit-animation-timing-function: ease-in;
  }
  50% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
  }
}
@keyframes noteBellBlink {
  0% {
    opacity: 0.2;
    -webkit-animation-timing-function: ease-in;
  }
  50% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
  }
}
#noteWrap .notificationsBoxes {
  height: auto;
  width: 96%;
  margin: 0 auto;
  display: block;
  padding: 1% 5% 1% 2%;
  font: 12px Raleway;
  color: #8c8c8c;
}
#noteWrap .dismissAllNotifications {
  justify-content: center;
  padding-bottom: 15px;
}
#noteWrap .new {
  -webkit-backface-visibility: hidden;
  -webkit-animation: noteBellBlink 1s linear 2 0s;
  -moz-animation: noteBellBlink 1s linear 2 0s;
  -o-animation: noteBellBlink 1s linear 2 0s;
  -ms-animation: noteBellBlink 1s linear 2 0s;
  animation: noteBellBlink 1s linear 2 0s;
}
.notificationsBoxesButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notificationsBoxesButtons .careButton {
  margin: 5px;
  padding: 2px 10px;
}
.newHealthStatus {
  -webkit-backface-visibility: hidden;
  -webkit-animation: noteBellBlink 1s linear 5 0s;
  -moz-animation: noteBellBlink 1s linear 5 0s;
  -o-animation: noteBellBlink 1s linear 5 0s;
  -ms-animation: noteBellBlink 1s linear 5 0s;
  animation: noteBellBlink 1s linear 5 0s;
}
div#notifications a {
  text-decoration: none;
  color: inherit;
  font: inherit;
}
div#directory {
  height: 100%;
  width: 0px;
  background: #1c6f9e; /*#F4F9FF;*/
  position: absolute;
  top: 126px;
  left: 0;
  overflow-y: scroll;
  margin-left: 0px;
}
div.directoryDoc {
  cursor: pointer;
}
div#dirWrap div {
  display: block;
}
div#doctorInfo {
  position: relative;
  height: 0px;
  display: none;
  margin: 10px 10px 5px 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.menuSel {
  /* Test commit */
  width: 150px;
  font-family: "Comfortaa", cursive;
  font-size: 16px;
  color: RGB(33, 35, 35);
  text-align: center;
  padding-top: 22px;
  float: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.menuSel:hover {
  color: #489dff;
}
div.menuSel:active {
  color: #587293;
}
.legacyWarning {
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  background: orange;
  padding: 20px;
  color: black;
  display: none;
}
.page {
  margin-top: 56px;
  display: none;
}
.containerMain {
  position: relative;
  overflow: auto;
  /*min-width:920px;
    max-width: 1600px;
    width: 100%;*/
  max-width: 970px;
  min-width: 850px;
  margin: 0 auto;
  min-height: 150px;
  background-color: white;
  /*margin-top:20px; */
  /*  box-shadow: 0px 0px white;
  border: 0px;
  border-radius: 3px;*/
  padding: 10px;
  padding-bottom: 40px; /*for the help chat*/
  margin-top: 56px;
}

body {
  background-color: #f2f2f2;
  font-size: 12px;
  line-height: unset;
}
@media screen and (min-width: 920px) {
  .containerMain {
    border: 1px solid #dddada;
    border-radius: 5px;
    margin-top: 56px;
  }
  /*.page {
    background-color: #f6f4f4;
  }*/
  body {
    background-color: #f6f4f4;
  }
  /* .displayCard {
    width: 22%;
    min-width: 295px;
    display: inline-block;
  }

  #appointmentCalendar {
    width: 65%;
    margin: 10px 20px 10px 20px;
    float: left;
  } */
}
/* @media screen and (min-width: 375px) {
  .displayCard {
    width: 22%;
    min-width: 295px;
    height: 600px;
    float: left;
    margin-top: 50px;
    display: grid;
  }
  #appointmentCalendar {
    width: 60%;
    margin: 10px 20px 10px 20px;
    float: left;
  }
}
@media screen and (min-width: 414px) {
  .displayCard {
    width: 22%;
    min-width: 295px;
    height: 600px;
    float: left;
    margin-top: 50px;
    display: grid;
  }
  #appointmentCalendar {
    width: 65%;
    margin: 10px 20px 10px 20px;
    float: left;
  }
} */
.landingPageSection {
  /*margin-left: -10px;*/
}
.lps_letterContainer {
  /*vertical-align: top;
  display: inline-block;
  border-right:5px solid #ffffff;*/
  width: calc(50px * 8);
  margin: 0 auto;
  margin-left: calc(100% - 425px);
}
.lps_letter {
  /*height:25px;
  width:35px;*/
  /*  line-height: 25px;*/
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 50px;
  color: #b5b5b5;
  font-size: 12px;
  font-family: Helvetica Neue;
}
.lps_letter div {
  margin-top: 3px;
  border-top: 1px solid;
  visibility: hidden;
}
.lps_letter:hover > div {
  border-top: 1px solid #cacaca;
  visibility: visible;
}
.lps_letter i {
  font-size: 15px;
  line-height: 20px;
}
.lps_letterDesc {
  display: inline-block;
  line-height: 23px;
  vertical-align: top;
  padding: 0 10px;
  font-weight: bold;
  color: #000000;
  display: none;
  width: 100%;
}
.lps_contentContainer {
  width: 100%;
  padding: 5px;
  display: inline-block;
  display: none;
  margin-top: -48px;
}
.patientLaunchBox {
  width: calc(25% - 13px);
  min-height: 100px;
  display: inline-block;
  padding: 15px 5px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  margin: 5px;
  text-align: center;
  vertical-align: top;
  font-size: 18px;
  font-family: Comfortaa;
  cursor: pointer;
}
.patientLaunchBox:hover {
  color: #489dff;
  border: 1px solid #489dff;
}
#landingPage_stats {
  margin: 15px 0px;
}
/*Remote Programs Modal END*/
div.leftMenu {
  overflow: auto;
  width: 200px;
  margin: 0 auto;
  min-height: 150px;
  background-color: white;
  margin-top: 20px;
  border: 1px solid RGB(230, 230, 230);
  border-radius: 3px;
  padding: 10px;
}
div.statisticBlock {
  width: 100%;
  height: 150px;
  background-color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 45px;
  width: calc(100% - 45px);
}
div.tableBlock {
  /*min-width: 880px;*/
  width: 100%;
  /*margin-left: 10px;
  margin-top: 10px;
  background-color: white;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.tableRow {
  position: relative;
}
div.titleRow {
  width: 100%;
  height: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom: 1px solid #95989a;
}
div.titleLabel {
  float: left;
  font-family: "Raleway";
  line-height: 21px;
  font-size: 12px;
  color: #489dff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
}
div.subTitleLabel {
  float: left;
  font-family: "Comfortaa", cursive;
  font-size: 12px;
  font-weight: bold;
  color: whitesmoke;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  height: 100%;
}
div.rowLabel,
.refEMR {
  float: left;
  height: 100%;
  font-family: "Raleway", cursive;
  font-size: 12px;
  color: RGB(79, 79, 79);
  min-height: 2px;
  /*margin-top: -1px;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
div.titleEven,
.refEMR {
  height: 100%;
  width: calc(100% - 0px);
  height: 25px;
  background-color: RGB(255, 255, 255);
  line-height: 25px;
  border-bottom: 1px solid #d4d4d4;
  border-top: 0px;
  margin-top: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.titleOdd {
  width: calc(100% - 0px);
  height: 25px;
  background-color: #ebf6ff;
  line-height: 25px;
  border-bottom: 1px solid #d4d4d4;
  border-top: 0px;
  margin-top: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.careButton {
  width: 75px;
  height: 25px;
  line-height: 25px;
  border: 1px solid #dedede;
  border-radius: 20px;
  font-family: "Raleway";
  font-size: 14px;
  color: #489dff;
  text-align: center;
  float: left;
  cursor: pointer;
  background-color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}
.careButton-cancel {
  color: #eb3d00;
}
.careButton:hover {
  color: #ffffff;
  background-color: #489dff;
  border: 1px solid #489dff;
}
.careButton-cancel:hover {
  color: white;
  background-color: #eb3d00;
  border: 1px solid #eb3d00;
}
.careButton:active {
  background-color: #587293;
  border: 1px solid #587293;
}
.careButton-cancel:active {
  background-color: #982800;
  border: 1px solid #982800;
}
div.cexDivider {
  width: 100%;
  height: 1px;
}
div.csDivider {
  width: 100%;
  height: 1px;
  display: inline-block;
  background-color: white;
}
div.addButton {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #dadada;
  color: #dadada;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
}
div.addButton:hover {
  background-color: #dadada;
  color: grey;
  cursor: pointer;
}
div.addButton:active {
  background-color: grey;
  color: white;
}
div.cexDroplist {
  width: 200px;
  min-height: 50px;
  /*max-height:120px;*/
  border: 1px solid #d2cece;
  border-radius: 5px;
  z-index: 999;
  position: absolute;
  font-family: "Raleway";
  font-size: 10px;
  font-weight: bold;
  color: #958b8b;
  line-height: 28px;
  overflow: auto;
  padding: 10px;
  background-color: white;
  box-shadow: 2px 2px 2px rgba(228, 227, 227, 0.5);
}
div#cexContainer {
  width: 100%;
}
div.cexDropUpperBar {
  float: left;
  padding-left: 5px;
  width: calc(100% + 20px);
  height: 30px;
  line-height: 25px;
  background-color: #f1efef;
  color: #8a8a8a;
  font-family: Comfortaa;
  font-size: 12px;
  font-weight: normal;
  margin-top: -10px;
  margin-left: -10px;
}
textarea.cexDropDialogEntry {
  font-size: 12px;
  font-weight: normal;
  font-family: Raleway;
  color: #489dff;
  height: 24px;
  line-height: 20px;
  resize: none;
  padding-left: 5px;
  border: 0px;
  border-bottom: 1px solid #489dff;
  margin-top: 0px;
  background: transparent;
  float: left;
  text-align: center;
  overflow: hidden;
}
div.cexDropAreaText {
  color: grey;
  font-size: 12px;
  float: left;
  font-weight: normal;
  margin-right: 5px;
}
i.cexDropArea {
  float: left;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 8px;
}
div.cexDroplist::-webkit-scrollbar {
  display: none;
}
div.dropRow {
  float: left;
  overflow: hidden;
  padding-left: 3px;
  width: 100%;
  height: 20px;
  line-height: 25px;
  font-weight: normal;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.dropRow:hover {
  background-color: #1a6494;
  color: white;
}
.athenaBrandIcon {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  /* background-image: url("../img/FLI_PA4P.jpeg"); */
  background-size: 15px;
  display: inline-block;
}
div.dropRow:hover .athenaBrandIcon {
  /* background-image: linear-gradient(
      rgba(26, 100, 148, 0.45),
      rgba(26, 100, 148, 0.45)
    ),
    url(../img/FLI_PA4P.jpeg); */
}
div.dropRow:active {
  background-color: #b5b5b5;
  color: white;
}
div.csName {
  font-family: "Comfortaa", cursive;
  font-size: 18px;
  color: #575757;
  display: inline-block;
}
div.csName:hover {
}
div.adminLabel {
  font-family: "Raleway";
  font-size: 12px;
  font-weight: bold;
  color: #3bb55d;
  display: inline-block;
  margin-left: 20px;
  margin-top: 10px;
  cursor: pointer;
}
div.adminLabel:hover {
  color: #607b9e;
}
div.addItemButton {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border: 2px solid #dedede;
  float: left;
  cursor: pointer;
}
div.addItemButton:hover {
  background-color: #dedede;
}
div.addItemButtonL {
  height: 24px;
  width: 40px;
  line-height: 22px;
  border-radius: 5px;
  /* padding-left: 4px; */
  text-align: center;
  background-color: white;
  border: 1px solid #dedede;
  float: left;
  cursor: pointer;
  margin-top: 2px;
}
div.addItemButtonL:hover {
  background-color: #1d6f9f;
  color: white;
}
div.innerSymbol {
  font-family: "Raleway";
  font-size: 36px;
  color: #84b7ff;
  line-height: 16px;
  width: 16px;
  text-align: center;
  float: left;
}
div.addItemButtonSM {
  height: 15px;
  width: 15px;
  border-radius: 5px;
  border: 2px solid #dedede;
  float: left;
  cursor: pointer;
}
div.addItemButtonSM:hover {
  background-color: #dedede;
}
div.addItemButtonSM:hover .innerSymbolSM {
  color: white;
}
div.innerSymbolSM {
  font-family: "Raleway";
  font-size: 22px;
  color: #84b7ff;
  line-height: 11px;
  width: 11px;
  text-align: center;
  float: left;
}
div.csSectionTitle {
  font-family: "Comfortaa", cursive;
  font-size: 12px;
  color: #489dff;
  line-height: 20px;
  text-align: left;
  display: inline-block;
  background-color: #489dff;
  color: white;
  position: relative;
}
div.tabCurveHolderRight {
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
div.tabCurveHolderLeft {
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: 20;
  transform: rotateY(180deg);
}
div.tabCurveSquare {
  left: 0;
  background: #489dff;
  height: 12px;
  width: 10px;
  position: absolute;
}
div.tabCurveIn {
  left: 10;
  position: absolute;
  /*background: radial-gradient(ellipse, transparent, transparent 10px, #489dff 10px, #489dff 10px, #489dff 10px);*/
  background: radial-gradient(ellipse, transparent 10px, #489dff 10px);
  background-size: 20px 20px;
  width: 10px;
  height: 10px;
}
div.tabCurveOut {
  top: 10;
  left: 0;
  transform: rotate(180deg);
  position: absolute;
  /*background: radial-gradient(ellipse, #489dff, #489dff 10px, transparent 8px, transparent 8px, transparent 0px);*/
  background: radial-gradient(ellipse, #489dff 10px, transparent 0px);
  background-size: 20px;
  width: 10px;
  height: 10px;
}
div.csSubsection {
  position: relative;
  padding: 5 5 5 0;
  /*float:left;*/
  width: 100%; /*280px;*/
  border-radius: 5px;
  /*border:1px solid #dedede;*/
  min-height: 0px;
  margin-top: 5px;
  /*border-right:1px solid #f6f6f6;*/
}
div.csSubsectionTitle {
  font-family: "Comfortaa";
  font-size: 12px;
  color: #489dff;
  text-align: left;
  display: inline-block;
  margin-left: 5px;
  position: relative;
}
div.csItemEntry {
  padding: 5px;
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  min-height: 45px;
  margin-top: 5px;
  font-family: "Raleway";
  font-size: 12px;
  color: #575757;
  border: 1px solid white;
  resize: none;
}
div.csItemEntry:hover {
  border: 1px dashed #dedede;
}
div.innerSpan {
  min-width: 100px;
  min-height: 15px;
}
@-moz-document url-prefix() {
  /*Manual settings for a contenteditable span in Firefox*/
  span.innerSpan[contenteditable="true"] {
    min-width: 100px;
    width: 100%;
    min-height: 1.5em;
    float: left;
    /*breaks long words into multiple lines - firefox needs it*/
    word-wrap: break-word;
    overflow: hidden;
  }
}
span.tagText {
  font-family: Raleway;
  font-size: 14px;
  /*border: 1px solid white;*/
  border-radius: 3px;
  padding: 2px;
}
span.tagText:hover {
  /*border: 1px solid #dedede;*/
  background-color: #f3efef;
}
div.csItemLabel {
  padding: 5px;
  float: left;
  width: calc(100% - 30px);
  border-radius: 5px;
  min-height: 20px;
  margin-top: 5px;
  font-family: "Raleway";
  font-size: 14px;
  color: #575757;
  border: 1px solid white;
}
div.csItemLabel:hover {
  border: 1px dashed #dedede;
}
div.dayDivider {
  width: 100%;
  margin-left: 0%;
  float: left;
  border: 0px solid #dedede;
  border-radius: 6px;
  overflow: hidden;
}
div.dayDivider:hover {
  background-color: #fbfbfb;
}
div.dayLabel {
  width: 60px;
  height: 14px;
  font: 10px "Helvetica Neue";
  color: #ffffff;
  background-color: #3290fc;
  vertical-align: top;
}
div.dayLabel div {
  text-align: center;
  display: inline-block;
  height: 14px;
  line-height: 14px;
}
div.contentDay {
  width: 100%;
  font-family: Raleway;
  font-size: 12px;
  color: #575757;
  margin-left: 32px;
}
div.subSecSpecial {
  float: left;
  font-family: Raleway;
  font-size: 12px;
  color: white;
  background-color: #b9b9b9;
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 4px;
}
div.normalText {
  float: left;
  font-family: Raleway;
  font-size: 12px;
  line-height: 14px;
  margin-right: 5px;
  margin-left: 5px;
}
div.normalText:hover .csCode:hover {
  background-color: #dedede;
}
div.tagICDText {
  float: left;
  font-family: Raleway;
  font-size: 12px;
  margin-right: 5px;
  margin-left: 5px;
}
div.tagICDText:hover .csCode:hover {
  background-color: #dedede;
}
div.csCircle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #dedede;
  float: left;
  margin-top: 2px;
}
div.medIcon {
  font-size: 18px;
  margin-top: 0px;
}
div.csCode {
  height: 16px;
  border-radius: 6px;
  border: 1px solid #dedede;
  float: left;
  margin-top: 4px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 10px;
  font-weight: bold;
}
div.csCode:hover {
  background-color: #dedede;
}
div.csIcdText {
  float: left;
  background-color: #f9f5f1;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 4px;
}
span.csTag {
  font-family: Raleway;
  font-size: 12px;
  color: black;
  width: 110px;
  text-align: left;
  display: inline-block;
}
span.csTagValue {
  font-family: Raleway;
  font-size: 12px;
  color: black;
  width: 40px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
}
span.csTagUnit {
  font-family: Raleway;
  font-size: 12px;
  color: black;
  width: 60px;
  text-align: left;
  font-style: italic;
  display: inline-block;
}
span.csTagSymbol {
  width: 5px;
  text-align: center;
  display: inline-block;
}
textarea.csUnitEntry {
  border: 0px solid;
  border-bottom: 1px solid #d0c7f3;
  float: left;
  font-family: raleway;
  font-size: 14px;
  margin-top: 3px;
  color: #575757;
  line-height: 22px;
  padding-left: 5px;
  height: 25px;
  overflow: hidden;
  width: 70%;
  resize: none;
}
input.csUnitEntry {
  float: left;
  font-family: raleway;
  font-size: 14px;
  margin-top: 5px;
  color: #575757;
  line-height: 22px;
  padding-left: 5px;
  margin-right: 5px;
  margin-left: 10px;
  overflow: hidden;
  resize: none;
}
div.csUnitWrapper {
  width: auto;
  border: 0px solid #cacaca;
  display: inline-block;
  margin-right: 5px;
}
div.csUnitsMeas {
  width: 20%;
  height: 30px;
  float: left;
  line-height: 40px;
  color: #d0c7f3;
}
div.csUnits {
  margin-bottom: 10px;
}
div.csUnitLabel {
  float: left;
  background-color: #d0c7f3;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  font-family: raleway;
  color: white;
  margin-top: -2px;
  line-height: 15px;
}
div.csUnitLabelCheckbox {
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  font-family: raleway;
  line-height: 22px;
}
li.list-group-item {
  padding: 0px;
  font-family: Raleway;
}
i.openChrono {
  font-size: 16px;
  /*float:left;*/
  display: inline-block;
  margin-left: 10px;
  /*color:#B7B4B4;*/
  color: #d8d6d6;
  visibility: hidden;
}
i.openChrono:hover {
  color: #489dff;
}
div.historyPopup {
  position: absolute;
  float: left;
  top: 25%;
  left: 25%;
  width: 170px;
  height: 130px;
  padding: 5px;
  z-index: 9999;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 2px 2px 2px rgba(228, 227, 227, 0.5);
}
div.ICDPopup {
  position: absolute;
  float: left;
  top: 0;
  left: 0;
  width: 300px;
  min-height: 150px; /*200 original*/
  padding: 5px;
  z-index: 9999;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 2px 2px 2px rgba(228, 227, 227, 0.5);
}
div.signaturePopup {
  position: absolute;
  float: left;
  top: 0;
  left: 0;
  width: auto;
  height: 100px;
  padding: 5px;
  padding-right: 15px;
  z-index: 9999;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 2px 2px 2px rgba(228, 227, 227, 0.5);
}
div.tlEntries {
  /* Rectangle 2: */
  /*background: #FAFAFA;*/
  width: 100%;
  height: 70px;
  float: left;
  position: relative;
}
div.tlEntryNumber {
  margin-left: 20px;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background: #dedede;
  float: left;
  margin-top: -20px;
  text-align: center;
  font-size: 10px;
  line-height: 15px;
  color: white;
}
div.tlEntryCLine {
  width: 20px;
  height: 1px;
  background: #dedede;
  float: left;
  position: absolute;
  margin-top: -12px;
}
div.tlCLine {
  position: absolute;
  float: left;
  height: 1px;
  /*background: #f1f1f1;*/
  background: white;
}
div.tlEntryBox {
  width: 60px;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 2px 2px 2px rgba(228, 227, 227, 0.5);
  float: left;
  position: relative;
  margin-top: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.tlEntryBox:hover {
  background-color: beige;
}
div.tlEntryBox:hover .tlEntryNumber {
  background-color: grey;
}
div.tlLines {
  /* Rectangle 2: */
  /*background: #FAFAFA;*/
  width: 100%;
  height: 20px;
  float: left;
  position: relative;
}
div.tlTime {
  /* Rectangle 2: */
  background: #fafafa;
  border: 1px solid #cacaca;
  border-radius: 20px;
  width: 100%;
  height: 28px;
  float: left;
  position: relative;
}
div.tlSubTime {
  /* Rectangle 2: */
  width: 100%;
  height: 50px;
  float: left;
  position: relative;
}
div.tlEpi {
  /* Rectangle 2: */
  background: #fafafa;
  border: 1px solid #cacaca;
  border-radius: 25px;
  height: 8px;
  float: left;
  position: absolute;
}
div.tlEpiIn {
  /* Rectangle 2: */
  background: #f7636f;
  border: 0px solid #cacaca;
  border-radius: 25px;
  height: 8px;
  width: 8px;
  float: left;
  margin-left: -1px;
  margin-top: -1px;
}
div.verticalText {
  transform: rotate(90deg);
  transform-origin: left top 0;
  line-height: 10px;
  color: white;
  width: 26px;
  height: 10px;
  font-size: 10px;
  background: #cacaca;
  float: left;
  text-align: center;
  position: absolute;
  margin-left: 10px;
  z-index: 9999;
}
div.verticalMonth {
  width: 1px;
  height: 26px;
  background: #eaeaea;
  float: left;
  position: absolute;
  z-index: 9998;
}
div.verticalLine {
  width: 1px;
  height: 50px;
  background: red;
  float: left;
  position: absolute;
}
div.episodeBar {
  position: absolute;
  border-radius: 5px;
  float: left;
  top: 10px;
  height: 5px;
  background: #005f79;
}
div.tagHover {
  position: absolute;
  float: left;
  top: 25%;
  left: 25%;
  width: 120px;
  height: 25px;
  z-index: 9999;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 2px 2px 2px rgba(228, 227, 227, 0.5);
}
div.smallEntryTag {
  font-size: 10px;
  float: left;
  background: #d0c7f3;
  width: 20px;
  border-radius: 2px;
  width: 12px;
  height: 12px;
  color: white;
  margin-left: 5px;
  width: 12px;
  height: 12px;
  /* padding-left: 2px; */
  text-align: center;
  line-height: 11px;
  margin-top: 5px;
}
i.scroller {
  font-size: 30px;
  color: rgba(137, 127, 255, 0.36);
  margin-top: 10px;
}
i.scroller:hover {
  color: rgba(137, 127, 255, 1);
}
div.paginationBox {
  float: left;
  width: 60px;
  height: 20px;
  margin-top: 0px;
  text-align: center;
  line-height: 18px;
  border: 1px solid #dedede;
  font-size: 14px;
  position: absolute;
  background-color: white;
  color: grey;
  border-radius: 4px;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}
div.paginationBox:hover {
  background-color: #e8e7e7;
  cursor: pointer;
}
div.paginationBox:active {
  background-color: #337ab7;
  color: white;
  border: 1px solid #337ab7;
}
div.paginationBaloon {
  float: left;
  width: 20px;
  height: 20px;
  margin-top: 0px;
  left: 540px;
  text-align: center;
  line-height: 18px;
  border: 1px solid #dedede;
  font-size: 12px;
  position: absolute;
  background-color: white;
  color: grey;
  border-radius: 10px;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}
div.paginationBaloon:hover {
  background-color: #e8e7e7;
  cursor: pointer;
}
div.paginationBaloon:active {
  background-color: #337ab7;
  color: white;
  border: 1px solid #337ab7;
}
div.paginationBaloon:hover .paginationBaloonLab {
  visibility: visible;
}
div.paginationBaloon:hover .paginationBaloonLabLine {
  visibility: visible;
}
div.paginationBaloonLab {
  height: 20px;
  width: 80px;
  float: left;
  /* position: absolute; */
  margin-top: 5px;
  /* border: 1px solid #dedede; */
  text-align: center;
  line-height: 20px;
  /* font-family: Raleway; */
  color: white;
  font-size: 12px;
  margin-left: -30px;
  background-color: #f7b86b;
  visibility: hidden;
}
div.paginationBaloonLabLine {
  height: 5px;
  width: 1px;
  float: left;
  margin-top: -25px;
  text-align: center;
  line-height: 5px;
  font-size: 12px;
  margin-left: 9px;
  background-color: #f7b86b;
  visibility: hidden;
}
div.paginationBaloonAlt {
  float: left;
  width: 4px;
  height: 20px;
  padding-top: 15px;
  margin-left: 14px;
  text-align: center;
  line-height: 18px;
  border: 1px solid #dedede;
  font-size: 8px;
  position: absolute;
  background-color: white;
  color: grey;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}
div.paginationBaloonAlt:hover {
  background-color: #e8e7e7;
  cursor: pointer;
}
div.paginationBaloonAlt:active {
  background-color: #337ab7;
  color: white;
  border: 1px solid #337ab7;
}
div.dataOwner {
  border-left: 5px solid rgba(107, 160, 90, 0.5);
  padding-left: 5px;
}
div.dataOwner:hover {
  border-left: 5px solid rgba(107, 160, 90, 1);
}
div.ownerComm {
  width: 100%;
  height: 0px;
  float: left;
  border-color: #eaeaea;
  background-color: #fdfbfb;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 10px;
  margin-top: 5px;
}
div.ownerComm:hover i.closeOwner {
  visibility: visible;
}
i.closeOwner {
  float: right;
  color: #489dff;
  margin-top: 5px;
  margin-right: 5px;
  font-size: 16px;
  visibility: hidden;
}
span.textDialogLabelICD,
div.textDialogLabelSig {
  font-family: Raleway;
  font-size: 14px;
  margin-left: 10px;
  color: grey;
}
span.textDialogLabelICD2,
div.textDialogLabelSig2 {
  font-family: Raleway;
  font-size: 10px;
  margin-left: 10px;
  color: #489dff;
  display: block;
  width: 100%;
}
i.addSection {
  color: #dedede;
  margin-right: 10px;
  font-size: 16px;
}
i.addSection:hover {
  color: #cac7c7;
  cursor: pointer;
}
i.buttonChronoChart {
  float: right;
  position: absolute;
  font-size: 16;
  color: #cacaca;
  margin-top: -16px;
}
i.buttonChronoChart:hover {
  color: grey;
  cursor: pointer;
}
i.buttonChronoChart:active {
  background-color: grey;
  color: white;
  cursor: pointer;
}
div.buttonChronoChart {
  float: right;
  position: absolute;
  width: 40px;
  height: 16px;
  border: 1px solid #cacaca;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #cacaca;
  margin-top: -16px;
}
div.buttonChronoChart:hover {
  color: grey;
  border: 1px solid grey;
  cursor: pointer;
}
div.buttonChronoChart:active {
  background-color: grey;
  color: white;
  border: 1px solid grey;
  cursor: pointer;
}
i.drawer {
  float: right;
  margin-right: 10px;
  position: absolute;
  left: 400px;
  margin-top: 30px;
  color: #cacaca;
  font-size: 20px;
}
i.drawer:hover {
  color: grey;
  cursor: pointer;
}
i.drawer:active {
  color: black;
}
div.sepLine {
  margin-left: 20px;
  margin-top: 10px;
  width: calc(100% - 20px);
  height: 1px;
  float: left;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,489dff+35,489dff+68,ffffff+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #ffffff 0%,
    #489dff 10%,
    #489dff 90%,
    #ffffff 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #ffffff 0%,
    #489dff 10%,
    #489dff 90%,
    #ffffff 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #489dff 10%,
    #489dff 90%,
    #ffffff 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
div.sendSeal {
  margin-top: 20px;
  margin-right: 20px;
  float: right;
  position: relative;
  width: 60px;
  height: 60px;
  border: 1px solid #cacaca;
}
div.sendSeal:hover {
  background-color: #489dff;
  border: 1px solid #489dff;
  color: white;
}
.csMessageEntryParent {
  float: left;
  min-height: 20px;
  font-family: "Raleway";
  font-size: 12px;
  color: #575757;
  resize: none;
  margin-left: 20px;
  width: calc(100% - 40px);
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  overflow-y: scroll;
}
.csMessageEntryFlow,
.csMessageEntryPortal {
  min-height: 60px;
  width: 100%;
  padding: 5px;
}
.csMessageEntryParent:hover {
  border: 1px dashed #dedede;
}
input.sendDialog::-webkit-input-placeholder {
  color: #cacaca !important;
}
input.sendDialog:-moz-placeholder {
  /* Firefox 18- */
  color: #cacaca !important;
}
input.sendDialog::-moz-placeholder {
  /* Firefox 19+ */
  color: #cacaca !important;
}
input.sendDialog:-ms-input-placeholder {
  color: #cacaca !important;
}
div.leftSelector {
  width: 100%;
  height: 30px;
  font-family: "Comfortaa", cursive;
  font-size: 16px;
  padding: 2px;
  text-align: right;
  line-height: 26px;
  color: #489dff;
  cursor: pointer;
  padding-right: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.leftSelector:hover {
  background-color: #efefef;
  color: #326eb3;
}
div.leftSelector:active {
  background-color: #489dff;
  color: white;
}
div.active-selector {
  background-color: #489dff;
  color: white;
}
div.lptoc {
  height: 6px;
  width: 1px;
  border: 0px;
  background: #d6d4d4;
  float: left;
  margin-top: 11px;
  margin-left: 3px;
}
div.lptocU {
  height: 6px;
  width: 1px;
  border: 0px;
  background: #d6d4d4;
  float: left;
  margin-top: -3px;
  margin-left: 3px;
}
div.lptxt {
  float: left;
  position: relative;
  margin-top: -26px;
  font-size: 12px;
  font-family: "Raleway";
  color: #8c8c8c;
  width: 100%;
  margin-left: -5px;
  padding-left: 5px;
}
.lptxt .actionItem,
.tableRow .coordReassign,
.tableRow .networkFinish,
.tableRow .referralFinish,
.tableRow .monitorFinish,
.tableRow .monitorPause,
.tableRow .monitorPlay {
  border: 1px solid #cacaca;
  background-color: white;
  border-radius: 100%;
  float: right;
  margin-top: -12px;
  margin-right: 4px;
  height: 25px;
  width: 25px;
  line-height: 23px;
  text-align: center;
}
div.lptxtU {
  float: left;
  position: absolute;
  margin-top: 32px;
  font-size: 12px;
  font-family: "Raleway";
  color: #8c8c8c;
}
div.reportsBox {
  /*float:left;*/
  border: 0px solid #cacaca;
  width: 100%;
  /*height:400px;*/
  margin-top: 10px;
}
/*\ div.reportRow {
  margin-top: 5px;
  min-height: 80px;
  height: auto;
  border: 1px solid white;
  padding: 3px;
  margin-left: 5px;
  border-radius: 5px;
  width: calc(100% - 10px);
}
div.reportRow:hover {
  border: 1px solid #cacaca;
} */
div.reportThumb {
  display: inline-block;
  width: 60px;
  height: 80px;
  /*border:1px solid #cacaca; */
  margin-left: 10px;
  vertical-align: top;
}
div.reportLine {
  display: inline-block;
  width: 3px;
  height: 80px;
  border: 0px solid #cacaca;
  margin-left: 5px;
  background: #3bb55d;
}
div.reportInfoBox {
  display: inline-block;
  width: 250px;
  vertical-align: top;
}
div.reportType {
  margin-left: 5px;
  font-family: "Raleway";
  font-size: 12px;
  font-weight: bold;
  color: grey;
}
div.reportDate {
  margin-left: 5px;
  font-family: "Raleway";
  font-size: 10px;
  font-weight: bold;
  color: #9c9c9c;
  margin-top: -5px;
}
div.reportOwner {
  margin-left: 5px;
  font-family: "Raleway";
  ont-size: 12px;
  color: #9791dc;
  font-style: italic;
}
div.reportFile {
  margin-left: 5px;
  font-family: "Raleway";
  font-size: 10px;
  font-weight: bold;
  color: #b7b3b3;
}
div.reportIconBox {
  width: 100%;
  margin-top: 3px;
}
i.reportIcon {
  font-size: 16px;
  color: #d7b43f;
  margin-left: 5px;
  float: left;
}
div.messagesRow {
  width: 100%;
  margin-top: 5px;
  /*margin-bottom: -8px;*/
  float: left;
  /*border:1px solid white;*/
  padding: 3px;
  /*margin-left: 2%;*/
  /*border-radius: 5px;*/
  /*margin-bottom:15px;*/
}
div.messagesRow:hover {
  /*border:1px solid #dedede;*/
  background-color: #f5f5f5;
}
div.messagesAvatar {
  float: left;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  background: white;
}
canvas.messagesAvatar {
  float: left;
  width: 30px;
  height: 30px;
  background: white;
}
div.messagesContent {
  float: left;
  width: calc(100% - 35px);
  margin-left: 5px;
}
div.messagesContent:hover {
}
div.messagesName {
  color: grey;
  font-family: "Helvetica";
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  display: inline-block;
  width: 65%;
  vertical-align: top;
}
div.messagesTime {
  font-size: 10px;
  color: #9a9a9a;
  height: 17px;
  line-height: 17px;
  display: inline-block;
  width: 30%;
  text-align: right;
}
div.messagesText {
  font-family: "Raleway";
  font-size: 12px;
  color: #6f6f6f;
  margin-top: 1px;
  line-height: 12px;
}
i.messagesIcon {
  color: #c9c9c9;
  font-size: 14px;
}
div.labelSend {
  float: left;
  margin-left: 20px;
  font-family: "Raleway";
  font-size: 14px;
  color: #4b4b4b;
  height: 18px;
  line-height: 18px;
  background-color: transparent;
}
input.inputSend {
  float: left;
  margin-left: 20px;
  position: relative;
  font-family: "Raleway";
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  margin-top: 0px;
  width: 240px;
  border: 0;
  color: #1f9005;
  outline: none;
  background-color: transparent;
  width: 250px;
  margin-top: 10px;
  border-bottom: 1px solid #a9d1ff;
}
i.closeAddDr {
  float: right;
  color: #bfbfbf;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 14px;
}
i.closeAddDr:hover {
  color: grey;
}
i.iconButtonTop {
  float: right;
  font-size: 22px;
  margin-top: 5px;
  color: #489dff;
  cursor: pointer;
  transition: all 0.2s ease-in;
}
i.iconButtonTop:hover {
  color: #3069ab;
}
div.mainNavigSel {
  float: left;
  width: 150px;
  height: 100%;
  text-align: center;
  font-size: 12px;
  font-family: Comfortaa;
  line-height: 20px;
  border: 0px;
  color: #5f5f5f;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.mainNavigSel:hover {
  /*background-color:#f3f3f3;*/
  color: black;
}
div.mainNavigSel:active {
  background-color: #489dff;
  color: white !important;
}
div.active-mainNavigSel {
  color: #489dff;
  /*color:white !Important;*/
}
div.inputMessage {
  background: #fff;
  font-size: 14px;
  width: calc(100% - 28px);
  margin-left: 38px;
  margin-bottom: 10px;
  margin-top: -40px;
  min-height: 30px;
  /* To have input box drop over the messages box while typing a long message
    min-height:30px;
    margin-top:5px;
    position:absolute;
    remove overflow
    z-index: 100;
    */
  padding-right: 5px;
  padding-top: 5px;
  padding-left: 8px;
  line-height: 16px;
  border: 2px solid #e0e0e0;
  font-family: "Raleway";
  color: #3d3c40;
  -webkit-appearance: none;
  box-shadow: none;
  outline: 0;
  resize: none !important;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  border-radius: 0 6px 6px 0;
  float: left;
}
div.leftButtonInput {
  background: #fff;
  border-radius: 6px 0 0 6px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  height: 30px;
  padding-top: 5px;
  line-height: 16px;
  border: 2px solid #e0e0e0;
  font-family: "Raleway";
  -webkit-appearance: none;
  box-shadow: none;
  outline: 0;
  resize: none !important;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  float: left;
  width: 30px;
  font-size: 40px;
  color: #a9a7a7;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.leftButtonInput:hover {
  color: grey;
  background-color: #dedede;
}
div.highlightRow {
  font-family: "Raleway";
  float: left;
  font-size: 12px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
  width: 100%;
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 60s linear infinite;
}
.rotating_slow {
  -webkit-animation: rotating 180s linear infinite;
}
div.doctorPlanetBox {
  width: 38px;
  height: 38px;
  border: 0px solid transparent;
  border-radius: 19px;
  position: absolute;
}
div.doctorPlanetBox:hover {
  border: 0px solid #dedede;
  background-color: rgba(222, 222, 222, 0.5);
  cursor: pointer;
}
div.doctorPlanet {
  width: 14px;
  height: 14px;
  border: 1px solid #dedede;
  position: absolute;
  border-radius: 7px;
  background-color: #f5f5f5;
}
div.patientPlanet {
  width: 10px;
  height: 10px;
  border: 1px solid #dedede;
  position: absolute;
  border-radius: 5px;
}
i.pageButton {
  float: left;
  width: 20px;
  height: 20px;
  color: #9ba2a3;
  font-size: 16px;
  cursor: pointer;
}
i.pageButton:hover {
  color: #5a6061;
}
div.pageLabel {
  float: left;
  width: 140px;
  text-align: center;
  height: 20px;
  font-family: "Comfortaa", cursive;
  font-size: 12px;
  color: #9ba2a3;
  margin-right: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input.searchTable {
  width: 200px;
  /*height: 20px;*/
  margin: 5px 0px 5px 5px;
  padding: 5px 10px;
  font-family: "Comfortaa", cursive;
  font-size: 14px;
  /*line-height: 20px;*/
  border: 1px solid #dedede;
  border-radius: 3px;
}
i.buttonAddPatient {
  /*float: right;*/
  font-size: 20px;
  margin-top: 10px;
  margin-left: 20px;
  color: #9ba2a3;
  cursor: pointer;
}
i.buttonAddPatient:hover {
  color: #489dff;
}
div.buttonAddMed {
  float: left;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 20px;
  color: #cacaca;
  cursor: pointer;
}
div.buttonAddMed:hover {
  color: #489dff;
}
div.saveProceed {
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  position: relative;
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Comfortaa;
  font-size: 10px;
  color: grey;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.saveProceed:hover {
  background-color: #489dff;
  color: white;
  border: 1px solid #489dff;
}
div.saveProceed:active {
  background-color: #254e7d;
  border: 1px solid #254e7d;
}
i.medCheck {
  float: left;
  position: absolute;
  top: -5px;
  font-size: 10px;
  background-color: white;
  color: #489dff;
}
div.cexTooltip {
  min-width: 160px;
  min-height: 50px;
  /*max-height:120px;*/
  border: 1px solid #d2cece;
  border-radius: 5px;
  z-index: 999;
  position: absolute;
  font-family: "Raleway";
  font-size: 10px;
  font-weight: bold;
  color: #958b8b;
  line-height: 28px;
  overflow: auto;
  padding-left: 10px;
  background-color: white;
  box-shadow: 2px 2px 2px rgba(228, 227, 227, 0.5);
}
div.statIndicator {
  float: left;
  margin-left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #dedede;
  margin-top: 12px;
}
div.good {
  background-color: #22b516;
  border: 0px solid #dedede;
}
div.normal {
  background-color: #ff8900;
  border: 0px solid #dedede;
}
div.bad {
  background-color: #ef2440;
  border: 0px solid #dedede;
}
span.rowStatText {
  float: left;
  font-family: "Comfortaa";
  font-size: 14px;
  color: #b5b4b6;
  margin-left: 10px;
}
div.actionLabel {
  color: #a7a7a7;
  margin-left: 15px;
  cursor: pointer;
  line-height: 24px;
  display: inline-block;
}
div.actionLabel i {
  margin-right: 10px;
  display: inline-flex;
  vertical-align: middle;
}
div.actionLabel:hover {
  color: black;
}

#forgot {
  width: 145px;
}
#userNameLabelMenu {
  /*background-color:RGB(26,100,148);*/
  z-index: 1000;
  position: relative;
}
#userNameLabelMenu ul,
#userNameLabelMenu li {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: RGB(26, 100, 148);
}
#userNameLabelMenu .file_menu {
  display: none;
  position: absolute;
  width: 200px;
  padding: 10px;
  height: auto;
}
#userNameLabelMenu .file_menu li {
  background-color: RGB(26, 100, 148);
  padding: 5px;
}
#userNameLabelMenu .file_menu li a {
  color: #ffffff;
  text-decoration: none;
}
#userNameLabelMenu .file_menu li a:hover {
  font-weight: bold;
  color: #fdb33d;
}
#userNameLabelMenu .file_menu .fa {
  width: 15px;
  text-align: center;
}
div.labelTicket {
  width: 100%;
  margin-bottom: 5px;
  color: white;
  font-family: "Comfortaa";
  font-size: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: none;
}
div#ticketLabelTrack,
div#ticketLabelFlow {
  display: block;
  width: 125%;
  height: 25px;
  padding-left: 50px;
  margin-left: -50px;
  background-color: #44bd68;
}
div#buttonTrackIcon {
  /*float: right;*/
  /*margin-right:50px;
    display: inline-block;*/
  /*margin-top: 10px;*/
  margin: 0 auto;
  font-size: 10px;
  color: #489dff;
  width: 21px;
  height: 21px;
  /* background-image: url("images/monitorIconI.png"); */
  background-size: 20px auto;
  cursor: pointer;
  /*display: none;*/
  /*opacity: 0;
    pointer-events: none;*/
  background-repeat: no-repeat;
}
div#buttonTrackIcon:hover {
  /* background-image: url("images/monitorIconA.png"); */
}
div#buttonTrackIcon:active,
div#buttonTrackIcon.active {
  /* background-image: url("images/monitorIconB.png"); */
}
div.sentPM {
  float: left;
  margin-left: 10px;
  margin-top: 6px;
  font-size: 10px;
  color: #489dff;
  width: 12px;
  height: 12px;
  /* background-image: url("images/monitorIconA.png"); */
  background-size: 12px auto;
}
div#buttonSendIcon {
  /*margin-right: 20px;*/
  /*margin-top: 10px;*/
  margin: 0 auto;
  font-size: 10px;
  color: #489dff;
  width: 21px;
  height: 21px;
  /* background-image: url("images/referIconI.png"); */
  background-size: 20px auto;
  cursor: pointer;
  /*opacity: 0;
    pointer-events: none;*/
  background-repeat: no-repeat;
}
div#buttonSendIcon:hover {
  /* background-image: url("images/referIconA.png"); */
}
div#buttonSendIcon:active,
div#buttonSendIcon.active {
  /* background-image: url("images/referIconB.png"); */
}
div.sentRE {
  float: left;
  margin-left: 10px;
  margin-top: 6px;
  font-size: 10px;
  color: #489dff;
  width: 12px;
  height: 12px;
  /* background-image: url("images/referIconA.png"); */
  background-size: 12px auto;
}
i.iC {
  position: relative;
  left: 95%;
  font-size: 20px;
  float: left;
  margin-top: -20px;
  display: none;
}
div.clda:hover i.iC {
  display: block;
}
#chronoChart {
  height: 175px;
  margin: 0 auto;
  margin-top: 65px;
  padding-bottom: 20px;
  position: relative;
}
#templateFrame {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: none;
}
.reportRawFileName {
  position: absolute;
  padding: 1px;
  max-height: 100px;
  font: 10px Raleway;
  bottom: 0;
  background-color: rgba(4, 4, 4, 0.69);
  color: white;
  border-radius: 5px;
  text-align: center;
  width: 90%;
  margin: 5%;
  word-wrap: break-word;
}
div.csSection {
  position: relative;
  padding: 0 10 10 10;
  /*float:left;*/
  display: inline-block;
  width: 100%;
  border-top: 2px solid white;
  background-color: white;
}
.btnDragSection {
  font: 12px "Raleway";
  height: 20px;
  line-height: 14px;
  border-radius: 5px;
  padding: 2px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: inline-block;
  border: 1px solid #95989a;
  background-color: white;
  cursor: move;
}
.dragNone {
  overflow: hidden;
}
.csSection.is-dragging {
  z-index: 10;
}
.slide-placeholder {
  background: #dadada;
  /*position: relative;*/
  float: left;
}
.slide-placeholder:after {
  content: " ";
  /*position: absolute;*/
  float: left;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15px;
  background-color: #fff;
}
#items-container {
  width: 100%; /* Total width of visible columns + border widths */
  height: 220px;
  /*border:solid 1px #000;
    background-color:#eee;*/
  overflow-x: scroll;
}

.items-window {
  width: 760px; /* Total width of all columns in sliding-window + border widths */
  height: 220px;
  overflow: hidden;
}

.items-window > div {
  float: left;
  display: block;
  width: 150px;
  height: 220px;
}
#sections-container {
  width: 80%; /* Total width of visible columns + border widths */
  height: 180px;
  /*border:solid 1px #000;
    background-color:#eee;*/
  overflow-x: scroll;
  display: inline-block;
}
#trashSections {
  width: 20%; /* Total width of visible columns + border widths */
  height: 180px;
  border: dashed 1px #000;
  /*background-color:#eee;*/
  overflow-x: scroll;
  display: inline-block;
  text-align: center;
}
#sections-window {
  width: 760px; /* Total width of all columns in sliding-window + border widths */
  height: 180px;
  overflow: hidden;
}

#sections-window > div {
  float: left;
  display: block;
  width: 150px;
  height: 180px;
}
div.outerFC {
  top: 50px;
  width: 80px;
  height: 40px;
  border: 0px solid #489dff;
  margin-left: -10px;
  position: absolute;
  overflow-x: scroll;
  margin-left: -10px;
  z-index: 1;
}
div.outerFC:hover {
  z-index: 9;
}
div.outerFC::-webkit-scrollbar {
  display: none;
}
div.labelFlagContainer {
  top: 50px;
  width: 0px;
  height: 40px;
  border: 0px solid #cacaca;
}
div.labelFlag {
  top: 50px;
  width: 80px;
  height: 40px;
  /*border: 1px solid #cacaca;*/
  float: left;
  background-color: white;
}
div.labelFlagH {
  top: 50px;
  width: 80px;
  height: 40px;
  /*border: 1px solid #cacaca;*/
  float: left;
  background-color: white;
  opacity: 0;
  -webkit-transition: opacity 0.5s; /* For Safari 3.1 to 6.0 */
  transition: opacity 0.5s;
}
div.wrapperFlag {
  opacity: 1;
  -webkit-transition: opacity 0.5s; /* For Safari 3.1 to 6.0 */
  transition: opacity 0.5s;
}
/*
div.labelFlag:hover .labelFlagH{
    opacity: 1.0;
}
div.labelFlag:hover .wrapperFlag{
    opacity: 0.0;
}*/
div.fLine {
  height: 16px;
  width: 1px;
  background-color: #489dff;
  float: left;
  margin-left: 13px;
  margin-top: 0px;
}
i.iflag {
  font-size: 12px;
  position: absolute;
  margin-top: 1px;
}
div.scrollZone {
  width: 20px;
  height: 40px;
  float: left;
  position: absolute;
  top: 50px;
  z-index: 10;
}
div.envelope:hover .scrollZone {
  background-color: rgba(0, 0, 255, 0.15);
}
div.labNum {
  width: 30px;
  text-align: center;
  height: 40px;
  float: left;
  position: absolute;
  top: 50px;
  z-index: 10;
  font-family: "Comfortaa";
  font-size: 30px;
  color: rgba(0, 0, 255, 0.15);
}
div.envelope:hover .labNum {
  color: rgba(0, 0, 255, 0.35);
}
.fixed-dialog {
  position: fixed;
  top: 50px;
  left: 50px;
}
div.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 1001;
}
div.helpA {
  float: left;
  margin-top: 210px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #dadada;
  text-align: center;
}
div.helpA:hover {
  background-color: #c7c7c7;
}
div.helpDots {
  float: left;
  margin-top: 10px;
  margin-left: 315px;
  width: 170px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
}
#loadingMessages {
  font-family: "Comfortaa", cursive;
  font-size: 12px;
  color: #9e9e9e;
  text-align: left;
  line-height: 8px;
  margin: 0 35px;
  padding: 10px;
  border-radius: 10px;
}
.select2-dropdown {
  font-size: 12px;
  font-family: Raleway, sans-serif;
}
.awv-sectionHeader {
  font-weight: bold;
  height: 23px;
  line-height: 25px;
  padding: 0 5px;
  margin-bottom: 10px;
  background-color: #1a6494;
  margin-left: -10px;
  font-family: "Comfortaa", cursive;
  font-size: 12px;
  color: #ffffff;
  min-width: 30%;
  display: inline-block;
}
.snapshot_selected {
  color: #489dff;
  font-weight: bold;
}
.snapshot_selected > span {
  color: #489dff;
  text-decoration: underline;
  font-weight: bold;
}

.strike_throughable {
  position: relative;
}

.strike_awv_button {
  color: #f44336;
  height: 18px;
  line-height: 16px;
  font-size: 12px;
  cursor: pointer;
  display: none;
}

.strike_throughable:hover .strike_awv_button {
  display: inline-block;
}

.striked *:not(.tooltip):not(.tooltip-inner) {
  text-decoration: line-through;
}

.cursor_pointer {
  cursor: pointer;
}

button.swal2-confirm.btn.btn-success {
  margin-right: 10px;
}

.rightArrow {
  position: fixed;
  bottom: 45%;
  left: 50%;
}

.messageBox {
  font-family: Helvetica Neue;
  /*padding-bottom: 10px 5px;*/
  margin: 10px 0 5px 0;
  /*border-bottom: 1px solid #D8D8D8;*/
}

.messageBox > .messageContent {
  /*background: #E3F3FD;*/
  font-size: 12px;
  line-height: 14px;
  padding: 6px 20px;
}
.messageBottom {
  width: 98%;
  margin: 0 auto;
  height: 1px;
  background-color: #cacaca;
  margin-top: 10px;
}
.messageHeader {
  width: 100%;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  font-family: Helvetica Neue;
  display: flex;
  align-items: center;
  color: #489dff;
  /*background-color: #F0F0F0;*/
  position: relative;
  /*border-top-left-radius: 5px;*/
  /*border-top-right-radius: 5px;*/
}

.messageButtons {
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  max-width: calc(40% - 38px);
  cursor: pointer;
  justify-content: space-evenly;
}

.thumbnailContainer {
  width: 100%;
  /*padding:5 10 5 10;*/
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}

.reportRow {
  box-flex: 1;
  -webkit-box-flex: 1;
  width: 50%;
  /*max-height: 200px;*/
  overflow: hidden;
  padding: 2px;
}

.reportRow img {
  /*height: 200px;*/
}

.reportRow:first-child:nth-last-child(4),
.reportRow:first-child:nth-last-child(4) {
  width: 50%;
}

.reportRow:first-child:nth-last-child(2),
.reportRow:first-child:nth-last-child(2) {
  width: 50%;
}

.reportRow:only-child {
  width: 100%;
  max-height: 200px;
  /*height: 100px;*/
  overflow: hidden;
}

div.carefluence_linkPatientOptionBox,
div.carefluence_linkPatientOptionBoxTemp {
  cursor: pointer;
  display: inline-block;
  margin: 2px;
  border: 2px solid #f7f7f7;
  border-radius: 3px;
  text-align: left;
  width: calc(50% - 8px);
  padding: 5px;
  background-color: #f7f7f7;
  font-size: 12px;
}
div.carefluence_linkPatientOptionBox:hover {
  border: 2px solid #489dff;
}
div.carefluence_linkPatientOptionBoxEntryLeft {
  display: inline-block;
  width: 85px;
  font-style: italic;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 800px;
  }
}
.new_pem_moal_body {
  height: 571px;
  padding: 15px 30px;
  overflow-y: auto;
}
.new_pem_moal_body .pemloaer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#new-PostEncounterModal .modal-dialog{
  margin-top: 0!important;
  /*min-width: 685px;*/
  width: 975px;
}
.newpemheader{
  height: 90px;
  background: #FBFBFB;
  padding: 13px 28px;
}
.newpem_title {
  font-size: 36px;
  line-height: 42px;
  color: #000000;
  font-family: Raleway;
  font-weight: normal;
}
.newpem_patient_name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  color: #5B8DEF;
}
.newpemButtonSection{
  margin: 20px 0;
    height: 44px;
    text-align: right;
}
.newEditProtocolBtn{
  /*
  width: 217px;
  height: 53px;
  background: #3E7BFA;
  color: #ffffff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  */
  margin-right: 10px;

}
.newEditProtocolCloseBtn{
  /*
  width: 217px;
    height: 53px;
    background: #FFF;
    color: #000;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    */
}
.newpemloaer{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto auto;
    width: 100px;
    height: 100px;
}
.proDecSec{
  margin-left: 13px;
}
.newpemprotocolSec {
  background: #FFFFFF;
  margin-top: 15px;
  display: none;
}
.newpemHeaderSec {
  display: flex;
  width: 100%;
  justify-content: space-between
}
.newpemprtocolComp {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: normal;
  display: flex;
}
.proImagSec{
  width: 51px;
  height: 51px;
  text-align: center;
}
.proImg{
  max-width: 100%;
  height: 100%;
}
.proHead{
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 29px;
  margin-bottom: 10px;
  color: #000000;
  text-transform: capitalize;
}
.proDes{
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0;
  color: #878682;
}
.newpemStatusComp{
  display: flex;
  font-family: 'Helvetica' ;
  font-style: normal;
}
.newStatusCode{
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  margin-top: -12px;
  margin-right: 7px;
}
.stageOneStatusCode {
  color: #66B5F8;
}
.stageTwoStatusCode {
  color: #FF8800;
}
.stageThreeStatusCode {
  color: #FF3B3B;
}
.stageFourStatusCode {
  color: #FF8080;
}
.stageFiveStatusCode {
  color: #06C270;
}
.stageZeroStatusCode {
  color: #39D98A;;
}
.newStatusDes{
  width: 300px;
  margin-left: 3px;
}
.newStatusSub{
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #878682;
  margin: 0;
}
.newStatusText{
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  text-transform: uppercase;
  margin: 0;
}
.newDateSec{
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
.dateCell {
  width: 40px;
  margin-right: 10px;
}
.dateCellTop{
  margin-top: -21px;
}
.todayImage {
  width: 16px;
  height: 15px;
  margin: 0 auto;
  margin-bottom: 12px;
}
.iconImg{
  max-width: 100%;
  max-height: 100%;
}
.todayText {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin: 0;
}
.disableBox{
    opacity: 0.5;
}
.highlightBox{
    background: #E7E7E7;
}
.dateMonth {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  text-transform: uppercase;
  margin: 0;
}
.dateBox {
  width: 38px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  border-radius: 3px;
}
.dateText {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin: 0;
}
.dateDayText{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  color: #000000;
  margin: 0;

}
.scoreSec {
  display: flex;
  margin-top: 10px;
}
.scoreTextCont{
  margin-right: 11px;
}
.scoreTextHead{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: right;

  color: #000000;
  margin-top: 20px;
  margin-right: 10px;
}
.scoreCellCont{
  display: flex;
}
.scoreCell{
  width: 37px;
  height: 37px;
  background: #39D98A;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
  margin-right: 10px;
  border-radius: 20px;
  margin: 10px 10px 0 3px;
}
.scoreText{
  margin: 4px;
  display: inline-block;
}
.statusSec {
  display: flex;
  margin-top: 15px;
}
.statusTextCont{
  margin-right: 10px;
  width: 127px;
}
.statusTextHead{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  color: #0063F7;
  margin-top: 4px;
  margin-right: 10px;
}
.statusCellCont{
  display: flex;
}
.statusCell{
  width: 38px;
  height: 26px;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  border-radius: 3px; 
  margin: 0 10px 0 2px;
  text-align: center;
}
.statusCellText{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  margin-top: 4px;
  color: #FFFFFF;
}
.symptomsSec {
  display: flex;
  margin-top: 15px;
}
.symptomsTextCont{
  margin-right: 10px;
  width: 127px;
}
.symptomsTextHead{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-transform: capitalize;
  color: #FF3B3B;
  margin-top: 4px;
  margin-right: 10px;
}
.symptomsCellCont{
  display: flex;
}
.newMonitorSection{
  padding-left: 15%;
}
.symptomsCell{
  width: 38px;
  height: 26px;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  border-radius: 3px; 
  margin: 0 10px 0 2px;
  text-align: center;
}
.symptomsCellHightlight{
  background: #FFCC00;
}
.symptomsCellText{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  margin-top: 4px;
  color: #000000;
}
.medicationsSec {
  display: flex;
  margin-top: 15px;
}
.medicationsTextCont{
  margin-right: 8px;
  width: 150px;
}
.medicationsTextHead{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  color: #FF8800;
  margin-top: 4px;
  margin-right: 10px;
}
.vitalsTextHead{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  color: #FF8800;
  margin-top: 4px;
  margin-right: 10px;
}
.medicationsCellCont{
  display: flex;
}
.medicationsCell{
  width: 38px;
  height: 26px;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  border-radius: 3px; 
  margin: 0 10px 0 2px;
  text-align: center;
}
.medicationsCellText{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  margin-top: 4px;
  color: #000000;
}
.visiblityHidden {
  visibility: hidden;
}
.hidden {
  display: none;
}
.stageZero {
  background: #39D98A; 
}
.stageOne {
  background: #66B5F8; 
}
.stageTwo{
  background: #FF8800;
}
.stageThree {
  background: #FF3B3B; 
}
.stageFour {
  background: #FF8080; 
}
.stageFive{
  background: #06C270;
}
.stageNone{
  background: #E7E7E7;
}
.disabledCell{
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  border-radius: 3px;
}

.cguContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 21px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #4F4F4F;
}

/* Hide the browser's default checkbox */
.cguContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.cgucheckmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #000;
}

/* On mouse-over, add a grey background color */
.cguContainer:hover input ~ .cgucheckmark {
  background-color: #FFF;
  border: 1px solid #000;
}

/* When the checkbox is checked, add a blue background */
.cguContainer input:checked ~ .cgucheckmark {
  background-color: #FFF;
  border: 1px solid #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.cgucheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cguContainer input:checked ~ .cgucheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cguContainer .cgucheckmark:after {
  left: 6px;
  top: -6px;
  width: 10px;
  height: 19px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* venkata css */
#enrollmentandsetup_Popup .modal-body {
  padding: 0px;
}

.enrollmentandsetup-body {
  padding: 20px 30px;
}
.flx-space-between {
  display: flex;
  justify-content: space-between;
}
.m-right-10 {
  margin-right: 10px;
}
.modal-close {
  float: right;
  color: black;
  background-color: #EBEBEB;
  border-radius: 50%;
  padding: 9px 10px;
  font-weight: 100;
  width: 30px;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
}

#enrollmentandsetup_Popup .modal-body, #enrollmentandsetup_Popup .modal-body {
  padding: 0px;
}
.enrollmentandsetup-head {
  background: #FBFBFB;
  padding: 10px 20px 20px 20px;
}
.enrollmentandsetup-head h2 {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.enrollmentandsetup-head .call-text {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #5B8DEF;
}

.enrollmentandsetup-body .notes {
  background: #FFFFFF;
  border: 1px solid #489DFF;
  box-sizing: border-box;
  border-radius: 7px;
}
.enrollmentandsetup-body .block-pad {
  padding: 14px;
}
.enrollmentandsetup-body .act-list {
  list-style: none;
  margin: 6px 0px;
  padding: 0px;
  float: left;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.enrollmentandsetup-body .act-list li{
  min-width: 15%;
  border-radius: 7px;
}
.enrollmentandsetup-body .s-label {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #595757;
  letter-spacing: 0.5px;
}
.enrollmentandsetup-body .s-label-bold {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #595757;
  letter-spacing: 0.5px;
}
.enrollmentandsetup-body .callPatient {
  font-weight: bold;
}
.enrollmentandsetup-body .callPatient img{
  height: 18px;
  margin-right: 5px;
}
.enrollmentandsetup-body .callPatient:hover img{
  /* content:url(/images/rpm/call-white.svg); */
}
.enrollmentandsetup-head .callPatient {
  font-weight: bold;
}
.enrollmentandsetup-head .callPatient img{
  height: 18px;
  margin-right: 5px;
}
.enrollmentandsetup-head .callPatient:hover img{
  /* content:url(/images/rpm/call-white.svg); */
}
.flx-space-between {
  display: flex;
  justify-content: space-between;
}
.m-right-10 {
  margin-right: 10px;
}
.modal-close {
  float: right;
  color: black;
  background-color: #EBEBEB;
  border-radius: 50%;
  padding: 9px 10px;
  font-weight: 100;
  width: 30px;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
}

.enrollmentandsetup-body .hyper {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FF3B3B;
  text-transform: uppercase;
}
.input-small {
  background: #FFFFFF;
  border: 1px solid #489DFF;
  box-sizing: border-box;
  border-radius: 4px;
}
.deviceType {
  max-width: 50%;
}
.txt-blue {
  color: #489DFF;
}

.scheduler-loading {
  top: 28% !important;
}
.remote-media-float {
  position: fixed!important;
  top: calc(100% - 18vw)!important;
  left: 30px;
  bottom: 30px;
  z-index: 1000;
  box-shadow: 0 0 10px #aaa;
  border-radius: 4px;
  width: 20vw!important;
  height: 15vw!important;
  overflow: hidden;
}
@media only screen and (max-width: 1024) {
  #new-PostEncounterModal .modal-dialog {
      width: 96%;
      margin: auto !important ;
  }
}

div#sideNav {
  position: absolute;
  top: 50px;
  left: -50px;
}