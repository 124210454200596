body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

#banner {
  background-color: RGB(26, 100, 148);
  height: 60px;
  width: 100%;
  position: relative;
  z-index: 960;
  padding: 10px 10px 0px;
}

#carex-logo {
  height: 125%;
  width: 138px;
  margin: -11px 0px;
}
.signInContainer {
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  border-radius: 6px;
}
.form-control {
  /* margin: 0 5px 5px 15px; */
  width: calc(100% - 45px);
  display: inline-block;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-family: Raleway;
}

.careButton {
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 13px;
  vertical-align: middle;
  line-height: 25px;
  font-size: 18px!important;
  height: auto!important;
  width: auto!important;
  padding: 8px 15px!important;
}
/* .careButton {
  width: 75px;
  height: 25px;
  line-height: 25px;
  border: 1px solid #dedede;
  border-radius: 20px;
  font-family: "Raleway";
  font-size: 14px;
  color: #489dff;
  text-align: center;
  float: left;
  cursor: pointer;
  background-color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
} */

.signInContainer-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding:0;
}
.customContainer {
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  border-radius: 6px;
}
.f-right {
  float: right;
}
.careButton:hover {
  text-decoration: none;
}
